import React, { useState } from 'react';
import axios from 'axios';
import { Script } from 'gatsby';
import Layout from '../components/Layout';

const GetStarted = () => {
  const [loaded, setLoaded] = useState(false);
  return (
    <Layout fullMenu hideFooter={true}>
      <Script
        src="https://insuranceform.app/embed.js"
        onLoad={() => setLoaded(true)}
      ></Script>
      {loaded && (
        <div style={{ width: '100%', marginTop: 100 }}>
          <Script id="salt-embed">
            {`new SaltEmbed(
    {
      link: "Li_fyukrRUGFag",
      form: "Fo_MGBlrjDOAu0",
    }
  )`}
          </Script>
        </div>
      )}
    </Layout>
  );
};

export default GetStarted;

export const Head = () => {
  return (
    <>
      <meta property="og:title" content="Agency Insurance Home and Auto" />
      <meta
        property="og:description"
        content="Agency Insurance Home and Auto"
      />
      <meta
        property="og:image"
        content="https://storage.googleapis.com/agencyinsurance/agency.png"
      />
      <meta property="og:url" content="https://agencyinsurance.us/KS" />
    </>
  );
};
